@import "initial";

//menu main setting
.ui.menu{
  background: colorBright(1);
  border: 1px solid colorBlue(0.25);
  box-sizing: border-box;
  box-shadow: 2px 3px 5px colorBlue(0.25);
  height: 50px;
  font-family: map-get($fontFamily, Roboto);
  font-size: map-get($fontSize, normal);
  padding: 0 1%;

  //special for fixed menu
  &.fixed{
    padding: 0 2.5%;
    border-radius: 0;
  }

  //generic item
  .item{
    &:before{
      background: none;
    }
  }

  //avatar
  .item{
    .ui.avatar.image{
      margin-right: 1em;
    }
  }

  //item
  a.item{
    color: colorDark(0.65);
    font-weight: map-get($fontWeight, bold);

    //for header
    &.header{
      margin-right: 30px !important;

      &.active{
        &:before{
          background: none;
        }
      }

      img{
        // height: 30px;
        width: auto;
      }

      &:hover{
        &:before{
          background: none;
        }
      }
    }

    //remove the border
    &:before{
      background: none;
    }

    //for the active item
    &.active{
      @include textGradient(colorPrimary(90deg));
      font-weight: map-get($fontWeight, black);

      &:hover{
        @include textGradient(colorPrimary(90deg));
        font-weight: map-get($fontWeight, black);
      }

      &:before{
        position: absolute;
        content: '';
        top: 45px;
        height: 3px;
        width: 100%;
        background: colorPrimary(90deg);
        border-radius: 100px 100px 0 0;
      }

      .icon{
        @include textGradient(colorPrimary(90deg));
      }

      .ui.label{
        -webkit-text-fill-color: white;
      }
    }

    &:hover{
      background: colorBright(0);
      // font-weight: map-get($fontWeight, bold);
      color: colorDark(0.8);

      &:before{
        position: absolute;
        content: '';
        top: 45px;
        height: 3px;
        width: 100%;
        background: colorBlue(0.5);
        border-radius: 100px 100px 0 0;
      }
    }
  }

  //for dropdown item
  .item.ui.dropdown{
    color: colorDark(0.65);
    font-weight: map-get($fontWeight, bold);

    &:before{
      background: none;
    }

    &:hover{
      background: none;
    }

    &:active{
      background: colorBlue(0.1);
      color: colorDark(0.8);
    }

    .menu{
      border: 1px solid colorBlue(0.25);
      box-sizing: border-box;
      box-shadow: 2px 3px 5px colorBlue(0.25);

      .item{
        color: colorDark(0.65) !important;
        font-weight: map-get($fontWeight, bold) !important;

        &:hover, &:active, &.active.selected{
          background: colorBlue(0.1) !important;
          -webkit-background-clip: border-box;
          -webkit-text-fill-color: colorDark(0.8) !important;
        }
      }
    }

  }

  &.inverted{
    background: colorBlue(1);
    border: 1px solid colorBlue(0.25);
    box-sizing: border-box;
    box-shadow: 2px 3px 5px colorBlue(0.25);
    height: 50px;
    font-family: map-get($fontFamily, Roboto);
    font-size: map-get($fontSize, normal);

    //generic item
    div.item{
      &:before{
        background: none;
      }
    }

    //item
    a.item{
      color: colorBright(0.8);
      font-weight: map-get($fontWeight, normal);

      //remove the border
      &:before{
        background: none;
      }

      //for the active item
      &.active{
        @include textGradient(colorBright(1));
        font-weight: map-get($fontWeight, black);

        &:hover{
          @include textGradient(colorBright(1));
          font-weight: map-get($fontWeight, black);
        }

        &:before{
          top: 46px;
          background: colorBright(1);
        }

        .icon{
          @include textGradient(colorBright(1));
        }
      }

      &:hover{
        background: colorBright(0);
        color: colorBright(0.9);

        &:before{
          top: 46px;
          background: colorBright(0.5);
        }
      }
    }

    //for dropdown item
    .item.ui.dropdown{
      color: colorBright(0.8);
      font-weight: map-get($fontWeight, normal);

      &:before{
        background: none;
      }

      &:hover, &:active{
        color: colorBright(0.9);
      }

      .menu{
        background: colorBlue(1);
        border: 1px solid colorBlue(0.25);
        box-sizing: border-box;
        box-shadow: 2px 3px 5px colorBlue(0.25);

        .item{
          color: colorBright(0.8) !important;
          font-weight: map-get($fontWeight, normal) !important;

          &:hover, &:active, &.active.selected{
            background: colorBright(0.1) !important;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: colorBright(0.9) !important;
          }
        }
      }

    }
  }

  &.secondary{
    .item{
      border-radius: 0;
      border-bottom: 2px solid colorBlue(0);

      &:hover{
        border-radius: 0;
        border-bottom: 2px solid colorBlue(0.5);
        background: transparent !important;
        color: colorDark(0.8) !important;
      }

      &.active{
        border-radius: 0;
        border-bottom: 2px solid colorBlue(1);
        @include textGradient(colorPrimary(90deg));

        &:hover{
          border-radius: 0;
          border-bottom: 2px solid colorBlue(1);
          @include textGradient(colorPrimary(0deg));
          background: colorPrimary(0deg) !important;
          -webkit-background-clip: text !important;
          -webkit-text-fill-color: transparent !important;
        }
      }
    }

    &.inverted{
      background: none;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      .item{
        &.active{
          background: colorBright(0.25) !important;
          -webkit-background-clip: border-box;
          -webkit-text-fill-color: colorBright(1) !important;
          font-weight: map-get($fontWeight, normal);

          &:hover{
            background: colorBright(0.25) !important;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: colorBright(1) !important;
            font-weight: map-get($fontWeight, normal);
          }
        }

        &:hover{
          background: colorBright(0.25) !important;
        }
      }
    }
  }

  &.vertical{
    height: auto;
    background: colorBright(1);
    border: 1px solid colorBlue(0.25);
    box-sizing: border-box;
    box-shadow: 2px 3px 5px colorBlue(0.25);
    font-family: map-get($fontFamily, Roboto);
    font-size: map-get($fontSize, normal);
    padding: 0;

    a.item{
      //remove the border
      &:before{
        background: none;
      }

      &:hover{
        background: colorBlue(0.1);
      }

      &.active{
        background: colorPrimary(45deg);
        -webkit-background-clip: border-box;
        -webkit-text-fill-color: colorBright(1);
        font-weight: map-get($fontWeight, normal);

        .icon{
          -webkit-text-fill-color: colorBright(1);
        }

        &:hover{
          background: colorPrimary(45deg);
          -webkit-background-clip: border-box;
          -webkit-text-fill-color: colorBright(1);
          font-weight: map-get($fontWeight, normal);
        }
      }
    }

    &.inverted{
      background: colorBlue(1);

      a.item{
        //remove the border
        &:before{
          background: none;
        }

        &:hover{
          background: colorBlue(0.1);
        }

        &.active{
          background: colorPrimary(45deg);
          -webkit-background-clip: border-box;
          -webkit-text-fill-color: colorBright(1);
          font-weight: map-get($fontWeight, normal);

          .icon{
            -webkit-text-fill-color: colorBright(1);
          }

          &:hover{
            background: colorPrimary(45deg);
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: colorBright(1);
            font-weight: map-get($fontWeight, normal);
          }
        }
      }
    }

    &.secondary{
      background: none;
      border: none;
      -webkit-box-shadow: none;
      box-shadow: none;

      .item{
        border-radius: 100px !important;

        &:hover{
          color: colorDark(0.9) !important;
          background: colorBlue(0.1) !important;
        }

        &.active{
          border-radius: 100px;
          background: colorPrimary(45deg);
          -webkit-background-clip: border-box;
          -webkit-text-fill-color: colorBright(1);
          font-weight: map-get($fontWeight, normal);

          &:hover{
            background: colorPrimary(45deg) !important;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: colorBright(1);
            font-weight: map-get($fontWeight, normal);
          }
        }
      }

      &.inverted{
        .item{
          &.active{
            background: colorBright(0.25) !important;
            -webkit-background-clip: border-box;
            -webkit-text-fill-color: colorBright(1) !important;
            font-weight: map-get($fontWeight, normal);

            &:hover{
              background: colorBright(0.25) !important;
              -webkit-background-clip: border-box;
              -webkit-text-fill-color: colorBright(1) !important;
              font-weight: map-get($fontWeight, normal);
            }
          }

          &:hover{
            background: colorBright(0.25) !important;
          }
        }
      }
    }
  }
}
