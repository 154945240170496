@import "initial";

//mixin for stats global
@mixin statisticDefault($font, $weight, $size, $opacity){
  font-family: map-get($fontFamily, $font) !important;
  font-weight: map-get($fontWeight, $weight) !important;
  font-size: map-get($fontSize, $size) !important;
  color: colorDark($opacity) !important;
}

//Global settings
.ui.statistic{
  .value{
    @include statisticDefault(Roboto, bold, h1, 0.8);
  }

  .label{
    @include statisticDefault(Roboto, bold, normal, 0.5);
    padding-top: 0.5em;
  }
}

.ui.statistics{
  display: block;

  .statistic{
    margin: 2em !important;
  }
}

//stats in inverted segment
.inverted{
  .ui.statistic{
    .value{
      color: colorBright(1);
    }

    .label{
      color: colorBright(0.5);
    }
  }
}
