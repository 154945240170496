.ui.menu{
  a.item{
    &.active{
      &:before{
        top: 46px;
      }
    }

    &:hover, &:focus{
      &:before{
        top: 46px;
      }
    }
  }
}
