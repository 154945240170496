#contributorGrid{
  height: 100%;
  background: blue;
}

#sideNavCon{
  background: red;
  padding: 0;

  &>.ui.segment{
    border-radius: 0;
    height: 100vh;
    overflow-y: auto;
    padding: 2.5em;

    &::-webkit-scrollbar {
        width: 0.5em;
    }

    &::-webkit-scrollbar-track {
        background-color: rgba(255,255,255,0.1);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255,255,255,0.5);
    }

    .ui.label{
      margin-bottom: 0.5em;
    }
  }
}

#avatarBox{
  position: relative;
}

#updateAvatarTrigger{
  position: absolute;
  bottom: 0;
  right: 5em;
  opacity: 0.5;
  padding: 0.7em;
}

#profileStats{
  padding: 0.25em;
  width: 100% !important;

  .ui.statistic{
    margin: 0.35em !important;
    display: block;
  }

  .ui.menu{
    padding: 1em;
    display: inline;

    .item{
      display: inline-block;
    }
  }
}

#contentColumn{
  padding: 0;

  .ui.menu{
    border-radius: 0;
  }
}

#settingButton{
  font-size: 20px;
  position: absolute;
  top: 14px;
  left: 35px;
}

.menu-cont{
  align-items: center;
  justify-content: center;
  text-align: center;
}