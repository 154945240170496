.sc-kAzzGY.jdZEYg{
    width: 50%;
    background: linear-gradient(45deg, #1976D2 0%, #00BCD4 100%);
    div{
        color: #ffffff
    }
}


.ui.divider{
  border-top: 1px solid colorBlue(0.25) !important;
}

.ui.statistic.total-statistic{
    background: linear-gradient(45deg, rgb(25, 118, 210) 0%, rgb(0, 188, 212) 100%);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
    border-radius: 3px;
    padding: 10px;

    .value,.label{
        color:#fff !important
    }
}

.ui.statistic.total-statistic:hover{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.project-progress{
    transform: rotate(-90deg);
    top: -161px;
    left: 173px;
    width: 70% !important;
    .progress{
        transform: rotate(90deg);
    }
    .bar{
        height: 35px !important;
    }
}

.ui.grid>.row{
    padding-bottom:0px !important;
}

.faq_container p {
    word-break: break-all;
}

.wrapped{
    .ui.secondary.menu{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
    }
}