//Open the comment for importing below if it will be used for the modular method
//@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

//Main font
$fontFamily:(
  Roboto: 'Roboto'
);

$fontWeight:(
  light: 300,
  normal: 400,
  bold: 700,
  black: 900
);

$fontSize:(
  h1: 48px,
  h2: 36px,
  h3: 28px,
  h4: 18px,
  normal: 15px,
  note: 13px
);

body{
  // background: colorBlue(0.05);
    background: url('../img/pattern.png');
    background-position: center;
}

//Return the dark color's opacity
@function colorDark($opacity){
  @return rgba(3, 28, 48, $opacity);
}

//Return the bright color's opacity
@function colorBright($opacity){
  @return rgba(255, 255, 255, $opacity);
}

//Return the blue color
@function colorBlue($opacity){
  @return rgba(33, 150, 243, $opacity);
}

//Return the dark blue color
@function colorBlueDark($opacity){
  @return rgba(13, 71, 161, $opacity);
}

//Return the cyan color
@function colorCyan($opacity){
  @return rgba(0, 188, 212, $opacity);
}

//Return the primary background
@function colorPrimary($deg){
  @return linear-gradient($deg, #1976D2 0%, #00BCD4 100%);
}

//Return the yellow background
@function colorYellow($deg){
  @return linear-gradient($deg, #FFC107 0%, #FFE082 100%);
}

//Return the red background
@function colorRed($deg){
  @return linear-gradient($deg, #F44336 0%, #E91E63 100%);
}

//Return the green background
@function colorGreen($deg){
  @return linear-gradient($deg, #4CAF50 0%, #8BC34A 100%);
}

//transition mixin
@mixin transition(){
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

//text gradient
@mixin textGradient($gradient){
  background: $gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
