//transition mixin
@mixin transition(){
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

#projectFolder{

  .ui.header{
    color: rgba(3, 28, 48, 0.8);
    margin-bottom: 0.75em;
    font-weight: 400;
  }

  a{
    .ui.header{
      margin-top: -0.5em;
    }

    img{
      margin-left: 5px;
      @include transition();
    }

    &:hover, &:focus{
      & img{
        transform: scale(1.05);
        @include transition();
      }

      & .ui.header{
        text-decoration: underline;
      }
    }
  }

  .ui.label{
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .ui.negative.button{
    padding: 8px 8px;
  }

  #addProject{
    margin-top: 1em;
    cursor: pointer;
    @include transition();

    &:hover{
      transform: scale(1.05);
      @include transition();
    }
  }
}
