//transition mixin
@mixin transition(){
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

#userListGrid{

  .ui.header{
    color: rgba(3, 28, 48, 0.8);
    margin-bottom: 0;
    font-weight: 400;
  }

  a{
    .ui.header{
      margin-top: 1em;
    }

    img{
      @include transition();
    }

    &:hover, &:focus{
      & img{
        transform: scale(1.05);
        @include transition();
      }

      & .ui.header{
        text-decoration: underline;
      }
    }
  }

  .ui.label{
    display: block;
    margin-top: 0em;
    margin-bottom: 1em;
    background: transparent;
  }

  .ui.negative.button{
    padding: 8px 8px;
  }

  #addProject{
    margin-top: 1em;
    cursor: pointer;
    @include transition();

    &:hover{
      transform: scale(1.05);
      @include transition();
    }
  }
}

#profileColumn{
  .ui.label{
    margin-top: -2em;
    margin-bottom: 1em;
  }

  .ui.tiny.message{
    display: block;
    padding: 0.75em 0.75em;
    margin-top: -0.5em;
  }
}

#activitiesColumn{
  .ui.feed{
    padding: 0;

    .event{
      padding: 0.75em 0;

      .label{
        i.icon{
          color: rgba(3, 28, 48, 0.7);
          font-size: 14px;
        }
      }

      .content{
        margin-left: 0.25em;

        .date{
          color: rgba(3, 28, 48, 0.5);
          font-size: 9pt;
          font-style: italic;
        }

        .summary{
          color: rgba(3, 28, 48, 0.8);
          font-weight: 500;
        }
      }
    }
  }

  .segment__header+.segment{
    max-height: 500px;
    overflow-y: auto;
  }
}

#userProfileGrid{
  .segment__header+.segment{
    padding-top: 0;
    // max-height: 500px;
  }

  .segment__header{
    i.icons{
      margin-right: 0.25em;
    }
  }

  .ui.tab.segment{
    border: 1px solid rgba(33, 150, 243, 0.5) !important;
    background: rgba(33, 150, 243, 0.01);
    border-radius: 5px;
  }
}
