//breadcrumb

.ui.breadcrumb{

  .section{
    font-size: 24px;
  }

  div.section{
    color: rgba(3, 28, 48, 0.8);
  }

  a{
    i.icon{
      margin-right: 0.4em;
    }

    font-weight: bold;
  }
}
